import { Box, Button, Grid, Typography } from "@mui/material";
import { ButtonPlant } from "../../PlantsPage/Plants.style";
import DefaultText from "../../../../components/Text/DefaultText";
import { Product, ProductSizes } from "../../../../gobalType";
import { useState } from "react";
import { theme } from "../../../../utils/theme";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { TypesAction } from "../../../../redux/typesAction";

interface IProps {
  item: Product | any;
}
function ItemSize({ item }: IProps) {
  const [currentGroupSize, setCurrentGroupSize] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function formatQuantityData(amount: number, thousandsSeparator = ",") {
    const roundedAmount = Math.round(amount * 100) / 100;

    const amountString = roundedAmount.toFixed(2);

    const [integerPart] = amountString.split(".");

    const integerWithThousands = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      thousandsSeparator
    );

    return integerWithThousands;
  }
  const handleArrowClick = () => {
    let count = item.product_sizes.length;
    if (indexOfLastSize > count) {
      setCurrentGroupSize(0);
    } else setCurrentGroupSize(currentGroupSize + 1);
  };
  const GroupSize = 3;
  const indexOfLastSize = (currentGroupSize + 1) * GroupSize;

  const indexOfFirstSize = indexOfLastSize - GroupSize;

  const currentSize = item?.product_sizes?.slice(
    indexOfFirstSize,
    indexOfLastSize
  );

  const handlePlantDetails = (
    product: Product | any,
    product_size: ProductSizes
  ) => {
    navigate(`/product-details/${product.id}`, {
      state: {
        product_id: product_size?.product_id,
        size_id: product_size?.id,
      },
    });
  };
  return (
    <Box className="item">
      <Box
        sx={{ width: { xs: "100%", sm: "38%" } }}
        className="content-products">
        <DefaultText
          style={{
            marginLeft: "15px",
            fontWeight: { xs: 600, sm: 500 },
            color: "#22292E",
          }}>
          {item?.name}
        </DefaultText>
      </Box>
      <Box
        className="size-products"
        sx={{
          minHeight: { xs: "40px", sm: "75px" },
          maxHeight: { xs: "35px" },
        }}>
        <Grid spacing={1} container className="list-sizes">
          {currentSize?.map((size: ProductSizes, index: number) => {
            return (
              <Grid item xs={4} className="size-detail" key={index}>
                {size?.quantity_available?.map(
                  (itemQty: any, index: number) => {
                    const quantity = formatQuantityData(itemQty?.quantity);
                    let value = size?.name?.concat(" - " + quantity + "pcs");
                    let valueNoSlice = size?.name?.concat(
                      " - " + quantity + "pcs"
                    );
                    if (value)
                      if (value?.length > 15 && value) {
                        value = value.slice(0, 13) + "...";
                      }
                    return index === 0 ? (
                      <Box>
                        {itemQty?.quantity > 0 ? (
                          <ButtonPlant
                            onClick={() => {
                              handlePlantDetails(item, size);
                            }}
                            style={{
                              backgroundColor: "#9ACC99",
                            }}
                            className="product-item-size">
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                },
                                marginBottom: 0,
                                fontWeight: 600,
                              }}>
                              <Box
                                sx={{ display: { xs: "block", sm: "none" } }}>
                                {value}
                              </Box>
                              <Box
                                sx={{ display: { xs: "none", sm: "block" } }}>
                                {valueNoSlice}
                              </Box>
                            </Typography>
                          </ButtonPlant>
                        ) : (
                          <Button className="product-item-size-max0">
                            <Typography
                              className="product-name"
                              sx={{
                                fontWeight: 600,
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                },
                                marginBottom: 0,
                              }}>
                              <Box
                                sx={{ display: { xs: "block", md: "none" } }}>
                                {value}
                              </Box>
                              <Box
                                sx={{ display: { xs: "none", sm: "block" } }}>
                                {valueNoSlice}
                              </Box>
                            </Typography>
                          </Button>
                        )}
                      </Box>
                    ) : (
                      ""
                    );
                  }
                )}
              </Grid>
            );
          })}
        </Grid>
        <ArrowForwardIosIcon
          onClick={() => {
            handleArrowClick();
          }}
          sx={{
            width: "20px",
            height: "20px",
            color: `${theme.palette.common.green}`,
            p: 2,
            cursor: "pointer",
          }}
        />
      </Box>
    </Box>
  );
}

export default ItemSize;
