import React, { memo, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { MenuLink } from "./header.style";
import { MenuType } from "./typing";
import Button from "@mui/material/Button";
import "./style.scss";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material";

import { useLocation, useNavigate } from "react-router";
import { TypesAction } from "../../redux/typesAction";

import Search from "./Seach";
import { RootState } from "../../redux";
const MenuMain: React.FC<{ pages: Array<MenuType> }> = ({ pages }) => {
  const { is_show_input } = useSelector((state: RootState) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  const theme = useTheme();

  useEffect(() => {
    const path = location.pathname;
    setActiveLink(path);
  }, [location]);

  const menuOnPress = (page: MenuType) => {
    if (page.id === 3) {
      dispatch({ type: TypesAction.CATEGORY_ID, payload: 1 });
      dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: true });
    } else {
      dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: false });
    }
  };

  const currentPath = window.location.pathname;

  useEffect(() => {
    console.log("change path", currentPath);
    window.scrollTo(0, 0);
  }, [currentPath]);

  return (
    <Container>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
            gap: "45px",
            alignItems: "center",
          },
        }}>
        {pages?.map((page) => (
          <MenuLink key={page.id} to={page.link}>
            <Button
              className="btn-appbar"
              onClick={() => menuOnPress(page)}
              sx={{
                my: 2,
                color:
                  activeLink === page.link
                    ? "#007F18"
                    : ` ${theme.palette.text.body}`,
                fontSize: 15,
                fontWeight: 700,
                display: "block",
                padding: "6px ",
              }}>
              {page.title}
            </Button>
          </MenuLink>
        ))}
        <Search />
      </Box>
    </Container>
  );
};
export default memo(MenuMain);
