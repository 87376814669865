import { AddToCart } from "./../pages/Cart/Cart.style";
export enum TypesAction {
  // type product
  GET_LIST_PRODUCTS = "GET_LIST_PRODUCTS",
  PRODUCT_INFO = "PRODUCT_INFO",
  CATEGORY_ID = "CATEGORY_ID",
  SHOW_INPUT_SEARCH = "SHOW_INPUT_SEARCH",
  PRODUCT_DETAIL = "PRODUCT_DETAIL",
  ITEM_ACTIVE = "ITEM_ACTIVE",
  //   Type home
  TAB_ACTION_ACTIVE = "TAB_ACTION_ACTIVE",

  // Type cart
  ADD_TO_CART = "ADD_TO_CART",
  EDIT_QUANTITY = "EDIT_QUANTITY",
  REMOVE_PRODUCT = "REMOVE_PRODUCT",
  RESET_CART = "RESET_CART",

  // type Auth
  AUTH = "AUTH",
  USERINFO = "USERINFO",
  VERIFYOTP = "VERIFYOTP",
  UPDATE_AVATAR = "UPDATE_AVATAR",

  //ORDER
  GET_ORDERS = "GET_ORDERS",

  //LIST NOTIFICATION
  GET_LIST_NOTIFICATION = "GET_LIST_NOTIFICATION",
  TOKEN_PUSH = "TOKEN_PUSH",

  ADD_IDS = "ADD_IDS",
  DELETE_IDS = "DELETE_IDS",
  COUNT_NOTI = "COUNT_NOTI",
}
