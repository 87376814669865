import React, { memo, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@material-ui/core/styles";
import Pages from "..";
import product from "../../assets/Images/product.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  AddToCart,
  BestSellers,
  // BestSellers,
  BtnPlaceYourOrder,
  Label,
  Table,
  TableRow,
  Td,
  Textaea,
  Th,
} from "./Cart.style";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { CartType, Product, ProductSizes } from "../../gobalType";

import { convertToNumber, formatMoneyData } from "../../utils/storage";
import {
  BASE_URL,
  HttpMethod,
  PATH_IMAGE_URL,
} from "../../services/api.constant";
import { callHttpClientMethod } from "../../services/callHttpClient";
import { countReadUnNoti, orderCart } from "../../services/apiList";
import BasicModal from "./components/Modal";
import LoadingCart from "./components/Loading";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import BestSallersCart from "./components/BestSallers";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { TypesAction } from "../../redux/typesAction";
import DefaultText from "../../components/Text/DefaultText";
import TitleText from "../../components/Text/TitleText";
import ModalConfirm from "./components/ModalConfirm";

const displayRes = {
  xs: "none",
  sm: "flex",
};

const Cart = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { carts } = useSelector((state: RootState) => state.cart);
  console.log(carts, "carts");
  const navigate = useNavigate();
  const { user, auth } = useSelector((state: RootState) => state.auth);
  const [note, setNote] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [orderCode, setOrderCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [changeQTY, setChangeQTY] = useState(false);
  const [indexDel, setIndexDel] = useState<any>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const totalPrice = useMemo(() => {
    let result = 0;
    carts?.map((item: CartType) => {
      return (result += Number(item.price) * Number(item.quantity));
    });
    return result;
  }, [carts, changeQTY]);

  const handleOrder = async () => {
    setLoading(true);
    const payload = {
      delivery_address: user.address,
      phone_number: user.phone_number,
      person_name: user.name,
      email: user.email,
      note,

      products: carts?.map((item) => {
        return {
          product_id: item.id,
          size_id: item.size_id,
          total: Number(item.quantity),
          price: item.price ? item.price : 0,
          category_id: item.isCategory === "pcs" ? 1 : 2,
        };
      }),
    };

    if (payload.delivery_address == null || payload.phone_number == null) {
      setOpenDialog(true);
      return;
    }
    try {
      const response = await callHttpClientMethod(
        BASE_URL + orderCart(),
        HttpMethod.Post,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth.access_token}`,
          },
        }
      );
      if (response.status_code === 200) {
        setLoading(false);
        setOrderCode(response?.data?.order_code);
        setShowOrder(true);
        dispatch({ type: TypesAction.RESET_CART });
        handleCountUnRead();
      }
    } catch (error: any) {
      toast.error(error?.message);
      setLoading(false);
    }
  };
  const handleCountUnRead = async () => {
    try {
      const respon = await callHttpClientMethod(
        BASE_URL + countReadUnNoti(),
        HttpMethod.Get,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth?.access_token}`,
          },
        }
      );
      if (respon?.status_code === 200) {
        dispatch({ type: TypesAction?.COUNT_NOTI, payload: respon?.data });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const gotoDetail = (item: any) => {
    navigate(`/product-details/${item.id}`, {
      state: {
        product_id: item?.product_id,
        size_id: item?.size_id,
      },
    });
  };

  const DelPro = () => {
    dispatch({
      type: TypesAction.REMOVE_PRODUCT,
      payload: indexDel,
    });
    setIndexDel(null);
    setOpenConfirm(false);
    toast.warn("Product has been deleted");
  };

  return (
    <Pages seo={{ title: "TBT Groups", description: "TBT Groups" }}>
      <Box
        sx={{
          backgroundColor: "#fff",
        }}
        className="bg_box">
        <Box
          textAlign="center"
          sx={{
            display: displayRes,
            justifyContent: "center",
            marginBottom: "20px",
          }}>
          <Typography
            variant="h2"
            sx={{
              textTransform: "uppercase",
              fontSize: "25px",
              color: "#50555c",
            }}>
            Your cart
          </Typography>
        </Box>
        <LoadingCart isOpen={loading} />
        <BasicModal
          isOpen={showOrder}
          handleClose={() => setShowOrder(false)}
          title={"Thanks for your placed order"}
          content={`Your Order confirmation No.${
            orderCode || ""
          } will ve sent to your email.`}
        />
        <ModalConfirm
          isOpen={openConfirm}
          handleClose={() => setOpenConfirm(false)}
          title={"Confirm"}
          content={`You definitely want to delete product ${carts[indexDel]?.name}?`}
          handleClear={DelPro}
        />
        {carts.length === 0 ? (
          <Box
            sx={{
              height: { sm: "400px", xs: "200px" },
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}>
            <DefaultText>The shopping cart is empty!</DefaultText>
          </Box>
        ) : (
          <Table aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    display: displayRes,
                    color: "transparent",
                  }}>
                  NO
                </TableCell>
                <TableCell id="cell" align="center">
                  PRODUCT
                </TableCell>
                <TableCell id="cell" align="center">
                  SIZE
                </TableCell>
                <TableCell id="cell" align="center">
                  QTY
                </TableCell>
                <TableCell id="cell" align="center">
                  PRICE
                </TableCell>
                <TableCell id="cell" align="right">
                  AMOUNT
                </TableCell>
                <TableCell id="cell"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {carts?.map((item: any, index: number) => {
                let totalPriceItem = Number(item.price) * Number(item.quantity);
                const imagePath =
                  item?.thumbnail &&
                  PATH_IMAGE_URL + item?.thumbnail[0]?.image_path;

                return (
                  <TableRow key={index}>
                    <TableCell
                      onClick={() => gotoDetail(item)}
                      sx={{ cursor: "pointer", display: displayRes }}>
                      <Box
                        component={"img"}
                        src={imagePath}
                        style={{ width: 80, height: 80 }}
                      />
                    </TableCell>
                    <TableCell
                      id="cell"
                      onClick={() => gotoDetail(item)}
                      sx={{ cursor: "pointer" }}>
                      {item.product_name}
                    </TableCell>
                    <TableCell id="cell" align={"center"}>
                      {item.sizeName !== "default" && item.sizeName}
                    </TableCell>
                    <TableCell id="cell">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <NumericFormat
                          className="number-input"
                          value={item.quantity}
                          inputMode="numeric"
                          allowNegative={false}
                          onChange={(event) => {
                            setChangeQTY(!changeQTY);
                            dispatch({
                              type: TypesAction.EDIT_QUANTITY,
                              payload: {
                                quantity: convertToNumber(event.target.value),
                                index,
                              },
                            });
                          }}
                          thousandSeparator=","
                        />
                        <Typography variant="span" color={"text.secondary"}>
                          {item.isCategory}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell id="cell" align={"center"}>
                      {formatMoneyData(item.price)}
                    </TableCell>
                    <TableCell id="cell" align={"right"}>
                      {formatMoneyData(totalPriceItem)}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: {
                          xs: "0px",
                          sm: "0px",
                          md: "16px",
                        },
                      }}
                      onClick={() => {
                        setIndexDel(index);
                        setOpenConfirm(true);
                      }}>
                      <ClearIcon className="delete_pro" />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={1}
                  sx={{ display: displayRes, borderWidth: 0 }}
                />
                <TableCell
                  id="cell"
                  colSpan={3}
                  sx={{ borderBottom: "0 !important" }}
                />
                <TableCell
                  id="cell"
                  colSpan={1}
                  sx={{ borderBottom: "0 !important" }}>
                  TOTAL AMOUNT
                </TableCell>
                <TableCell
                  id="cell"
                  align="right"
                  sx={{ borderBottom: "0 !important" }}>
                  {formatMoneyData(totalPrice)}
                </TableCell>
                <TableCell
                  id="cell"
                  sx={{ borderBottom: "0 !important" }}></TableCell>
              </TableRow>
            </TableHead>
          </Table>
        )}

        <Box sx={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <TitleText style={{ fontWeight: "700", marginTop: "20px" }}>
            BEST SELLER{" "}
          </TitleText>
          <BestSallersCart changeQTY={() => setChangeQTY(!changeQTY)} />
          {carts.length !== 0 && (
            <Box>
              <DefaultText style={{ marginBottom: "10px" }}>
                Please kindly note your inquiry as below (if any)
              </DefaultText>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <Textaea
                  style={{
                    display: "block",
                    alignSelf: "center",
                  }}
                  placeholder="Enter text"
                  rows={5}
                  onChange={(event) => {
                    setNote(event.target.value);
                  }}
                />
              </Box>
              <Box
                textAlign={"center"}
                mt={"20px"}
                mb={"60px"}
                onClick={() => handleOrder()}>
                <BtnPlaceYourOrder className="btn_order">
                  <DefaultText>Place Your Order</DefaultText>
                </BtnPlaceYourOrder>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Pages>
  );
};

export default memo(Cart);
