import React, { memo } from "react";
import { Helmet } from "react-helmet";

export type SeoProps = {
  title?: string;
  description?: string;
};

const Seo: React.FC<SeoProps> = ({ title = "A.V.R Nursery", description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default memo(Seo);
