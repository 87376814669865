export const BASE_URL = "https://avradmin.mobileplus.info/api";

export const PATH_IMAGE_URL = "https://avradmin.mobileplus.info/";
export const HttpMethod = {
  Post: "post",
  Get: "get",
  Delete: "del",
  Put: "put",
  Patch: "patch",
};
