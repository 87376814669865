import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { Td } from "../Cart/Cart.style";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";

import "./style.scss";
import { useEffect, useRef, useState } from "react";
import { callHttpClientMethod } from "../../services/callHttpClient";
import {
  BASE_URL,
  HttpMethod,
  PATH_IMAGE_URL,
} from "../../services/api.constant";
import {
  login,
  logout,
  updateAvatar,
  updateUser,
  userInfo,
} from "../../services/apiList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  Modal,
  Paper,
  Table,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ChangePassword from "./components/ChangePassword";
import { useNavigate } from "react-router";
import { TypesAction } from "../../redux/typesAction";
import { theme } from "../../utils/theme";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import axios from "axios";

import ModalPreviewAvatar from "./components/ChangePassword/ModalPreviewAvt";
import { faL } from "@fortawesome/free-solid-svg-icons";
import UpdateUser from "./components/UpdateUser";

function Account() {
  const { user, auth } = useSelector((state: RootState) => state.auth);
  const { tokenPush } = useSelector(
    (state: RootState) => state.listNotification
  );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirmLogout, setOpenConfirmLogout] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(user?.email);
  const [name, setName] = useState(user?.full_name);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_number);
  const [address, setAddress] = useState(user?.address);
  const [avatar, setAvatar] = useState("");
  const [file, setFile] = useState("");
  const [openPreviewAvatar, setOpenPreviewAvatar] = useState<boolean>(false);

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConfirmLogout = () => {
    setOpenConfirmLogout(true);
  };

  const handleCloseConfirmLogout = () => setOpenConfirmLogout(false);

  const handleLogout = async () => {
    setLoading(true);
    const token_Push = {
      token_push: tokenPush,
    };

    const responsive = await callHttpClientMethod(
      BASE_URL + logout(),
      HttpMethod.Post,
      token_Push,
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    if (responsive) {
      setLoading(false);
      navigate("/login");
      handleCloseConfirmLogout();
      dispatch({ type: TypesAction.VERIFYOTP, payload: email });
    } else {
      handleCloseConfirmLogout();
    }
  };

  const getUserInfo = async () => {
    const dataUser = await callHttpClientMethod(
      BASE_URL + userInfo(),
      HttpMethod.Get,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );

    dispatch({
      type: TypesAction.USERINFO,
      payload: { loadingUser: false, dataUser: dataUser.data },
    });
    handleClose();
  };

  const handlePreviewAvatar = (e: any) => {
    setAvatar(e.target.files[0]);
    const fileURL = URL.createObjectURL(e.target.files[0]);
    fileURL && setFile(fileURL);
    setOpenPreviewAvatar(true);
  };
  const handleUpdateAvatar = async () => {
    const formData = new FormData();
    formData.append("avatar", avatar);

    await axios({
      method: "post",
      url: `${BASE_URL}${updateAvatar()}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    })
      .then((response) => {
        toast.success("Update avatar successfully!");
        dispatch({
          type: TypesAction.USERINFO,
          payload: { dataUser: response.data.data, loadingUser: false },
        });
      })
      .catch(function (error) {
        toast.error(error);
      });

    setOpenPreviewAvatar(false);
  };

  const hanldeOrderHistory = () => {
    navigate("/order-history");
  };

  const styleIcon = {
    width: 30,
    height: 30,
    cursor: "pointer",
  };

  const styleTitle = {
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
    marginBottom: 0,
    // marginTop: 1,
  };
  const styleLogout = {
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
    marginBottom: 0,
  };

  const styleBoxLogout = {
    width: { xs: "75%", sm: "50%", md: "35%" },
    top: "50%",
    position: "absolute" as "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    padding: 20,
    boxShadow: 24,
    borderRadius: 4,
    outLine: "none",
    p: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  if (loading) {
    return (
      <Box
        sx={{
          margin: { xs: 15, sm: 32 },
          display: "flex",
          justifyContent: "center",
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }

  return (
    <Box
      maxWidth={"sm"}
      sx={{
        margin: "auto",
        marginBottom: 5,
        marginTop: -5,
        backgroundColor: "#fff",
        padding: { xs: "20px", sm: "40px" },
        borderRadius: { xs: "none", sm: 5 },
        boxShadow: { xs: "none", sm: 7 },
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Box>
          <Avatar
            sx={{ width: 120, height: 120 }}
            alt="Remy Sharp"
            src={PATH_IMAGE_URL + user?.avatar}
          />
          <input
            accept="image/*"
            name="avatar"
            style={{ display: "none" }}
            type="file"
            id="change_avatar"
            onChange={handlePreviewAvatar}
          />
          <label htmlFor="change_avatar" style={{ cursor: "pointer" }}>
            <Box
              sx={{
                color: "rbg(5,5,5)",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                position: "absolute",
                marginTop: -4,
                marginLeft: 10,
                backgroundColor: "#E4E6EB",
              }}>
              <CameraAltIcon sx={{ color: "rbg(5,5,5)", fontSize: "20px" }} />
            </Box>
          </label>
        </Box>

        <Typography
          sx={{ fontSize: 21, fontWeight: 700, lineHeight: 2, marginTop: 1 }}>
          {user?.full_name}
        </Typography>
      </Box>

      <Table aria-label="caption table">
        <TableRow className="item-profile" sx={{ textAlign: "center" }}>
          <Td>
            <Box>
              <Typography sx={styleTitle}>phone no</Typography>
              <Typography sx={{ marginBottom: 1 }}>
                {user?.phone_number}
              </Typography>
            </Box>
          </Td>
          <Td sx={{ textAlign: "right" }}>
            <EditIcon onClick={handleOpen} sx={styleIcon} />
          </Td>
        </TableRow>
        <TableRow className="item-profile">
          <Td>
            <Box>
              <Typography sx={styleTitle}>email</Typography>
              <Typography sx={{ marginBottom: 1 }}>{user?.email}</Typography>
            </Box>
          </Td>
          <Td sx={{ textAlign: "right" }}>
            <EditIcon onClick={handleOpen} sx={styleIcon} />
          </Td>
        </TableRow>
        <TableRow className="item-profile">
          <Td>
            <Box>
              <Typography sx={styleTitle}>address</Typography>
              <Typography sx={{ marginBottom: 1 }}>{user?.address}</Typography>
            </Box>
          </Td>
          <Td sx={{ textAlign: "right" }}>
            <EditIcon onClick={handleOpen} sx={styleIcon} />
          </Td>
        </TableRow>
        <TableRow className="item-profile">
          <Td>
            <Box>
              <Typography sx={styleTitle}>password</Typography>
              <Typography sx={{ marginBottom: 1 }}>*************</Typography>
            </Box>
          </Td>
          <Td>
            <ChangePassword />
          </Td>
        </TableRow>
        <TableRow className="item-profile order">
          <Td onClick={hanldeOrderHistory}>
            <Typography sx={styleTitle}>order history</Typography>
            <Typography sx={{ paddingBottom: 2 }}></Typography>
          </Td>
          <Td></Td>
        </TableRow>
      </Table>
      <Button
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          marginTop: 3,
        }}
        onClick={handleOpenConfirmLogout}>
        <LogoutIcon sx={styleIcon} />
        <Typography sx={styleLogout}>Log out</Typography>
      </Button>
      <ModalPreviewAvatar
        isOpen={openPreviewAvatar}
        urlAvatar={file}
        handleClose={() => {
          setOpenPreviewAvatar(false);
        }}
        handleUpload={handleUpdateAvatar}
      />
      <Modal
        open={openConfirmLogout}
        onClose={handleCloseConfirmLogout}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Paper sx={styleBoxLogout} className="modal-confirm-logout">
          <Typography variant="h6" fontWeight={600}>
            Are you sure you want to sign out?
          </Typography>
          <div className="line-horizontal"></div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
            }}>
            <button onClick={handleCloseConfirmLogout} className="btn-cancel">
              Cancel
            </button>
            <button onClick={handleLogout} className="btn-confirm">
              Confirm
            </button>
          </Box>
        </Paper>
      </Modal>
      {open && (
        <UpdateUser
          isOpen={open}
          // urlAvatar={file}
          handleClose={handleClose}
          // handleUpdateUser={handleUpdateUser}
          getUserInfo={getUserInfo}
        />
      )}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography sx={{ textAlign: "center", fontWeight: 600 }}>
            EDIT ACCOUNT INFO
          </Typography>
          <Box
            onClick={handleClose}
            sx={{ position: "absolute", right: 4, top: 4 }}>
            <ClearIcon />
          </Box>

          <FormControl sx={responInput}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              defaultValue={user?.full_name}
              placeholder="Enter fullname"></TextField>
          </FormControl>
          <FormControl sx={responInput}>
            <TextField
              disabled
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={user?.email}
              placeholder="Enter email"></TextField>
          </FormControl>
          <FormControl sx={responInput}>
            <TextField
              inputMode="numeric"
              onChange={(e) => setPhoneNumber(e.target.value)}
              defaultValue={user?.phone_number}
              placeholder="Enter phone number"></TextField>
          </FormControl>
          <FormControl onSubmit={handleUpdateUser} sx={responInput}>
            <TextField
              id="enter_edit"
              onChange={(e) => setAddress(e.target.value)}
              defaultValue={user?.address}
              placeholder="Enter address"></TextField>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {!loading ? (
              <Button
                sx={{
                  paddingLeft: 4,
                  paddingRight: 4,
                  borderRadius: 10,
                  width: 200,
                  margin: "auto",
                  backgroundColor: "#e4e5e9",
                  color: `${theme.palette.text.body}`,
                  ":hover": { backgroundColor: "#e4e5e9" },
                }}
                variant="contained"
                color="success"
                type="submit"
                onClick={handleUpdateUser}>
                Save
              </Button>
            ) : (
              <LoadingButton
                sx={{
                  paddingLeft: 4,
                  paddingRight: 4,
                  width: 200,
                  borderRadius: 10,
                }}
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined">
                <span>Save</span>
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Modal> */}
    </Box>
  );
}

export default Account;
