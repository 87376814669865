export const companyInfo = () => "/company-info";
export const productLists = () => "/product";
export const sizeDetail = () => "/product/size-detail";
export const keyWord = () => "/keyword";
export const allPlants = () => "/product/all-plant-web";
export const bestSellers = () => "/product/best-sellers";
export const special = () => "/product/special";
export const category = () => "/category/sub?parent_id=1";
export const login = () => "/login";
export const logout = () => "/logout";
export const userInfo = () => "/user/detail";
export const updateUser = () => "/user/update";
export const orderCart = () => "/order";
export const changePass = () => "/user/change-password";
export const register = () => "/register";
export const validateOtpRegister = () => "/validate-otp-register";
export const resendOtpRegister = () => "/resend-otp-register";
export const validateOtp = () => "/validate-otp";
export const updateCertificate = () => "/user/update-certificate";

export const forgotPass = () => "/forgot-password";
export const resetPass = () => "/reset-password";
export const historyOrders = (): string => "/order";
export const detailHistoryOrder = (order_code: string) =>
  `/order/detail/${order_code}`;
export const updateAvatar = () => "/user/update-avatar";

export const saveTokenFirebase = () => "/save-fcm-token";
export const notification = () => "/notification";
export const notiLoadMore = (page: number) => `/notification?page=${page}`;
export const deleteNoti = () => "/notification/delete";
export const deleteAll = () => "/notification/deleteAll";
export const detailNoti = (id: number) => `/notification/detail/${id}`;
export const readNoti = () => "/notification/read-noti";
export const readAllNoti = () => "/notification/read-all-noti";
export const countReadUnNoti = () => "/notification/count-noti-unread";
export const seenAll = () => "/notification/seen-all";
