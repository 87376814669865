import React, { memo, useEffect, useState, Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import { BASE_URL, HttpMethod } from "../../services/api.constant";
import { callHttpClientMethod } from "../../services/callHttpClient";
import "./style.scss";
import { companyInfo } from "../../services/apiList";
import imgTotal from "../AboutUs/assets/Rectangle 36.webp";
import { Box, Typography } from "@mui/material";
import Pages from "..";
import Row from "../../components/Header/common/Row";
import Col from "../../components/Header/common/Col";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { TypesAction } from "../../redux/typesAction";
import { useNavigate } from "react-router";
import plantMobile from "../HomePage/assets/Plant.png";

const AboutUs: React.FC = () => {
  const [company, setCompany] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadData = async () => {
    const response = await callHttpClientMethod(
      BASE_URL + companyInfo(),
      HttpMethod.Get,
      {},
      {}
    );
    setCompany(response);
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: 32,
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }

  return (
    <Box
      sx={{ paddingLeft: { xs: "15px" }, paddingRight: { xs: "15px" } }}
      className="main-about-us">
      <Pages>
        <Row className="about-us-total">
          <Col md={6} className="about-us-total__info">
            <Typography className="title-about-us ">About Us</Typography>
            <Typography className="fs">CALL/TEXT: {company?.fax}</Typography>
            <Typography className="fs">E-MAIL: {company?.email}</Typography>
            <Typography className="fs about-us-details">
              {company?.about_us}
            </Typography>
          </Col>
          <Col
            sx={{ display: { xs: "none", sm: "block" } }}
            md={6}
            className="image-total">
            <Box
              sx={{ width: "100%", height: 400 }}
              component={"img"}
              src={imgTotal}
              alt="imgTotal"
            />
          </Col>
        </Row>

        <Box
          className="product-aboutus-mobile"
          sx={{
            display: { xs: "flex", sm: "none" },
          }}>
          <Box
            onClick={() => {
              dispatch({ type: TypesAction.CATEGORY_ID, payload: 1 });
              dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: true });
              navigate("/products-plants");
            }}
            component={"img"}
            src={plantMobile}
            width={"100%"}
            height={"auto"}
          />
        </Box>
        <Box sx={{ py: { sm: "30px" } }}>
          <Typography
            variant="h3"
            className="fs"
            sx={{ fontSize: "20px", textTransform: "uppercase" }}>
            {company?.company_name}
          </Typography>
          <Typography sx={{ marginBottom: "20px" }} className="fs">
            {company?.address}
          </Typography>
          <Box className="map-location" sx={{ width: "100%" }}>
            <div
              className="location"
              onClick={() =>
                window.open(
                  `${company?.location}`,
                  "_blank",
                  "rel=noopener noreferrer"
                )
              }></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img
                src={company?.image_map}
                alt="mapLocation"
                style={{
                  width: "100%",
                  maxHeight: "700px",
                  objectFit: "cover",
                  display: "block",
                }}
              />
            </div>
          </Box>
        </Box>
      </Pages>
    </Box>
  );
};

export default memo(AboutUs);
