import { initializeApp } from "firebase/app";
import { useState } from "react";
const firebaseConfig = {
  apiKey: "AIzaSyDbdmGiKZFeziGnqDr2FaKTPbRuvWPtUJI",
  authDomain: "avr-project-ac902.firebaseapp.com",
  projectId: "avr-project-ac902",
  storageBucket: "avr-project-ac902.appspot.com",
  messagingSenderId: "125491763152",
  appId: "1:125491763152:web:30c1672aa9966631414bd5",
  measurementId: "G-LT8FP8E54S",
};

const firebaseAppTBT = initializeApp(firebaseConfig);

const requestPermission = () => {
  console.log("=================THỰC HIỆN REQUEST===========================");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      return permission;
    } else {
      console.log("User Permission Denied.");
    }
  });
};

export { firebaseAppTBT, requestPermission };
