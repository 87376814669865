import * as React from "react";

import "./style.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import ReactPaginate from "react-paginate";
import { Product } from "../../../../gobalType";

import { Box, CircularProgress, Container } from "@mui/material";

import Pages from "../../..";

import Search from "../../../../components/Header/Seach";
import ItemSize from "./ItemSize";
const ProductCard = () => {
  const { product_lists, loadingPf } = useSelector(
    (state: RootState) => state?.products
  );

  const [currentPage, setCurrentPage] = React.useState(0);

  const currentPath = window.location.pathname;

  const PageSize = 10;

  const indexOfLastItem = (currentPage + 1) * PageSize;
  const indexOfFirstItem = indexOfLastItem - PageSize;
  let currentItems;
  if (product_lists?.length > 0) {
    currentItems = product_lists?.slice(indexOfFirstItem, indexOfLastItem);
  }

  const handlePageClick = (products: Product | any) => {
    setCurrentPage(products.selected);
  };

  if (loadingPf) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: 32,
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }
  return (
    <Pages>
      {product_lists?.length > 0 ? (
        <Box
          sx={{
            width: { sm: "110%", md: "100%" },
          }}>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
            className="info-products__heading">
            <Box className="title-heading">
              <Box className="title-plant">NAME</Box>

              <Box className="title-value-size">AVAILABILITY</Box>
            </Box>
          </Box>
          <Box className="container-size">
            {currentItems?.map((item, index) => {
              return <ItemSize item={item} />;
            })}
          </Box>
        </Box>
      ) : (
        <Box sx={{ fontSize: { xs: 15, sm: 18 } }} className="data-empty">
          No data!
        </Box>
      )}

      {product_lists?.length > PageSize && (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(product_lists?.length / PageSize)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      )}
    </Pages>
  );
};
export default ProductCard;
