import React, { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";

import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { MenuType } from "./typing";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ParkIcon from "@mui/icons-material/Park";
import GrassIcon from "@mui/icons-material/Grass";
import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useLocation, useNavigate } from "react-router";
import { TypesAction } from "../../redux/typesAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import Avatar from "@material-ui/core/Avatar";
import { theme } from "../../utils/theme";
import { ButtonLogin } from "./header.style";
import "./style.scss";
import { PATH_IMAGE_URL } from "../../services/api.constant";
import Noti from "./Noti";
import Search from "./Seach";
import SearchIcon from "@mui/icons-material/Search";

const MenuMobile: React.FC<{ pages: Array<MenuType> }> = ({ pages }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { is_show_input } = useSelector((state: RootState) => state.products);
  const { user, auth } = useSelector((state: RootState) => state.auth);
  const { carts } = useSelector((state: RootState) => state.cart);
  const [showTab, setShowTab] = useState(false);
  const [showSearchInMobile, setShowSearchInMobile] = useState(false);
  const tabLogged = [
    {
      text: user?.full_name || "",
      path: "/account",
      icon: <Avatar alt="Remy Sharp" src={PATH_IMAGE_URL + user?.avatar} />,
    },
    { text: "ABOUT US", path: "/about-us", icon: <ApartmentIcon /> },
    { text: "PLANTS", path: "/products-plants", icon: <ParkIcon /> },

    {
      text: "YOUR CART",
      path: "/cart",
      icon: (
        <Box sx={{ position: "relative" }}>
          {carts.length !== 0 && (
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: "red",
                position: "absolute",
                right: -10,
                top: -5,
                borderRadius: 10,
                color: "white",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {carts.length}
            </div>
          )}
          <ShoppingCartIcon />
        </Box>
      ),
    },
  ];
  useEffect(() => {
    setShowSearchInMobile(false);
  }, [location.pathname]);
  const tabNotLogin = [
    { text: "About us", path: "/about-us", icon: <ApartmentIcon /> },
    { text: "Plants", path: "/products-plants", icon: <ParkIcon /> },

    {
      text: "Your cart",
      path: "/login",
      icon: <ShoppingCartIcon />,
    },
  ];

  const dataTab = auth?.access_token ? tabLogged : tabNotLogin;

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => setShowTab(false)}
      onKeyDown={() => setShowTab(false)}>
      <List>
        {dataTab.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(item.path);
                if (item.path === "/products-plants") {
                  dispatch({ type: TypesAction.CATEGORY_ID, payload: 1 });
                }
                if (item.path === "/products-pots") {
                  dispatch({ type: TypesAction.CATEGORY_ID, payload: 2 });
                }
              }}>
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === item.path
                      ? "#007F18"
                      : ` ${theme.palette.text.body}`,
                }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText>
                <div
                  style={{
                    marginTop: 16,
                    color:
                      location.pathname === item.path
                        ? "#007F18"
                        : ` ${theme.palette.text.body}`,
                    textTransform: "uppercase",
                  }}>
                  {item.text}
                </div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        display: {
          xs: "flex",
          md: "none",
          height: 70,
          paddingLeft: 10,
          paddingRight: 10,
          alignItems: "center",
        },
      }}>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        {is_show_input && <Search />}
      </Box>
      {!auth?.access_token && (
        <ButtonLogin className="btn_login_mobile" to="/login">
          LOGIN
        </ButtonLogin>
      )}
      {carts.length !== 0 && (
        <div
          style={{
            width: 20,
            height: 20,
            backgroundColor: "red",
            position: "absolute",
            right: 0,
            top: 8,
            borderRadius: 10,
            color: "white",
            fontSize: 12,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {carts.length}
        </div>
      )}

      <Box sx={{ marginRight: 1 }}>{auth?.access_token && <Noti />}</Box>

      <MenuIcon
        onClick={() => {
          setShowTab(true);
        }}
      />
      <Drawer
        sx={{ zIndex: 99999 }}
        anchor={"right"}
        open={showTab}
        onClose={() => setShowTab(false)}>
        {list()}
      </Drawer>
    </Box>
  );
};
export default memo(MenuMobile);
