import * as React from "react";
import { Box, CircularProgress, Grid, Paper } from "@mui/material";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import "./style.scss";
import {
  BASE_URL,
  HttpMethod,
  PATH_IMAGE_URL,
} from "../../../../services/api.constant";
import { bestSellers } from "../../../../services/apiList";
import { Link, useNavigate } from "react-router-dom";
import { theme } from "../../../../utils/theme";
import { Product, ProductSizes } from "../../../../gobalType";
import { callHttpClientMethod } from "../../../../services/callHttpClient";
import { useDispatch } from "react-redux";
import { TypesAction } from "../../../../redux/typesAction";
import { useMediaQuery } from "react-responsive";
import DefaultText from "../../../../components/Text/DefaultText";

interface IProps {
  dataSpecials: Product[];
}

const SpecialView = (dataSpecials: IProps) => {
  const dataSpecial = dataSpecials?.dataSpecials;

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const [rightBtnDisabled, setRightBtnDisabled] = React.useState<boolean>(true);
  const isIphone = useMediaQuery({
    query: "(min-width: 390px) and (max-width : 429px) ",
  });

  React.useEffect(() => {
    if (dataSpecial?.length > 4) {
      setRightBtnDisabled(false);
    }
  }, [dataSpecial?.length]);

  const carousel = React.useRef<any>();

  const gotoDetail = (item: Product) => {
    navigate(`/product-details/${item.id}`, {
      state: {
        product_id: item?.product_sizes?.[0]?.product_id,
        size_id: item?.product_sizes?.[0]?.id,
      },
    });
  };

  return (
    <Box
      className={"carousel-special"}
      sx={{
        width: "100%",
      }}>
      <Box
        sx={{
          width: "100%",
          margin: "auto",
        }}>
        {dataSpecial?.length > 0 ? (
          <AliceCarousel
            mouseTracking
            // responsive={responsive}
            disableDotsControls
            autoWidth
            ref={(el) => (carousel.current = el)}
            onSlideChanged={(e: any) => {
              if (e?.item >= dataSpecial?.length - 4) {
                setRightBtnDisabled(true);
              } else {
                setRightBtnDisabled(false);
              }
            }}
            renderPrevButton={({ isDisabled }: any) => (
              <KeyboardArrowLeftIcon
                className={`btn__left ${isDisabled ? "disabled" : ""}`}
                sx={{
                  fontSize: isIphone ? 30 : 40,
                  top: "35%",
                  left: "10px",
                  display: {
                    sm: "block",
                    xs: "none",
                  },
                }}
              />
            )}
            renderNextButton={() => (
              <ChevronRightIcon
                className={`btn__right ${rightBtnDisabled ? "disabled" : ""}`}
                sx={{
                  fontSize: isIphone ? 30 : 40,
                  top: "35%",
                  right: "0px",
                  display: {
                    sm: "block",
                    xs: "none",
                  },
                }}
              />
            )}>
            {dataSpecial?.map((item: Product, index: number) => {
              return (
                <Box key={index} className="bg_item_sallers">
                  <Box
                    className="item-best-sallers"
                    onClick={() => gotoDetail(item)}>
                    <Box
                      component={"img"}
                      src={
                        item?.thumbnail?.[0]?.image_path
                          ? PATH_IMAGE_URL + item?.thumbnail?.[0]?.image_path
                          : ""
                      }
                      alt="image"
                      sx={{
                        width: "100%",
                        height: 200,
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                      }}
                    />
                    <DefaultText
                      style={{ padding: "5px", textAlign: "center" }}>
                      {item?.name}
                    </DefaultText>
                  </Box>
                </Box>
              );
            })}
          </AliceCarousel>
        ) : (
          <Box
            sx={{
              fontSize: "20px",
              display: "flex",
              margin: "30px 0",
              justifyContent: "center",
              alignItems: "center",
            }}>
            No data !
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SpecialView;
