import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Container from "../../components/Header/common/Container";
import { callHttpClientMethod } from "../../services/callHttpClient";
import { BASE_URL, HttpMethod } from "../../services/api.constant";
import { historyOrders } from "../../services/apiList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { Td, Th } from "../Cart/Cart.style";
import { formatMoneyData } from "../../utils/storage";
import "./style.scss";
import { theme } from "../../utils/theme";
import moment from "moment";
import { useNavigate } from "react-router";
import DefaultText from "../../components/Text/DefaultText";
import { TypesAction } from "../../redux/typesAction";
import TitleText from "../../components/Text/TitleText";
import Search from "../../components/Header/Seach";

function OrderHistory() {
  const { auth } = useSelector((state: RootState) => state.auth);
  const orderState = useSelector((state: RootState) => state.order);
  const [order, setOrder] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const loadData = async () => {
    setLoading(true);

    const response = await callHttpClientMethod(
      BASE_URL + historyOrders(),
      HttpMethod.Get,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );

    setOrder(response?.data);
    dispatch({ type: TypesAction.GET_ORDERS, payload: response?.data });
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  const renderStatusText = (order: any) => {
    switch (order) {
      case "ORDER_PLACED":
        return "Order placed";
      case "ORDER_APPROVED":
        return "Order approved";
      case "ORDER_SHIPPING":
        return "Order shipped";
      case "ORDER_ARRIVED":
        return "Order arrived";
      case "ORDER_PAID":
        return "Order paid";
      case "CANCEL":
        return "Order cancel";
      default:
        return "";
    }
  };
  const handleOrderDetail = (order: any) => {
    navigate("/order-detail", { state: order });
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: { xs: 20, sm: 32 },
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }
  return (
    <Container
      sx={{
        backgroundColor: "#fff",
      }}
      className="bg_order"
      maxWidth="md"
      component="main">
      <Typography
        sx={{
          fontWeight: 600,
          marginBottom: 1,
          textAlign: "center",
          marginTop: { xs: "-40px", sm: "0px" },
          fontSize: { xs: "16px", sm: "24px" },
        }}>
        ORDER HISTORY
      </Typography>

      {order?.length > 0 ? (
        <Table aria-label="caption table">
          <TableHead>
            <TableRow className="item-header">
              <TableCell id="cell" align="center">
                Order No.
              </TableCell>
              <TableCell id="cell" align="center">
                Date
              </TableCell>
              <TableCell id="cell" align="center">
                Amount
              </TableCell>
              <TableCell id="cell" align="center">
                Status
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {orderState.order?.map((item: any, index: number) => {
              return (
                <TableRow
                  onClick={() => handleOrderDetail(item.order_code)}
                  key={index}
                  className="item-order">
                  <TableCell id="cell" align="center">
                    <DefaultText
                      style={{
                        marginBottom: 0,
                        fontWeight: 600,
                      }}>
                      {item.order_code}
                    </DefaultText>
                  </TableCell>
                  <TableCell id="cell" align="center">
                    <DefaultText>
                      {moment(item.created_at).format("DD/MM/YYYY")}
                    </DefaultText>
                  </TableCell>
                  <TableCell id="cell" align="right">
                    <DefaultText
                      style={{
                        marginBottom: 0,
                        width: { xs: "90%", sm: "65%" },
                      }}>
                      {formatMoneyData(item.total_price)}
                    </DefaultText>
                    <DefaultText style={{ flex: 1 }}> </DefaultText>
                  </TableCell>
                  <TableCell sx={{ display: "flex" }} id="cell" align="left">
                    <DefaultText style={{ width: "37%" }}> </DefaultText>
                    <DefaultText
                      style={{
                        flex: 1,
                        marginBottom: 0,
                        textTransform: "uppercase",
                      }}>
                      {renderStatusText(item.status)}
                    </DefaultText>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 5,
            fontSize: { xs: 16, sm: 20 },
          }}>
          No data!
        </Box>
      )}
    </Container>
  );
}

export default OrderHistory;
