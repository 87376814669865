import HomePage from "./pages/HomePage";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import ProductDetails from "./pages/ProductDetails";
import Cart from "./pages/Cart";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Account from "./pages/Account";
import ValidateOtp from "./pages/Register/components/ValidateOtp";
import ForgotPassword from "./pages/Login/components/ForgotPassword";
import OrderHistory from "./pages/OrderHistory";
import OrderHistoryDetail from "./pages/OrderHistory/components/OrderHistoryDetail";
import ListNotification from "./pages/ListNotification";

import {
  getMessaging,
  isSupported,
  onMessage,
  getToken,
} from "firebase/messaging";
import { firebaseAppTBT, requestPermission } from "./firebase";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux";
import { TypesAction } from "./redux/typesAction";
import { callHttpClientMethod } from "./services/callHttpClient";
import { countReadUnNoti, seenAll } from "./services/apiList";
import { BASE_URL, HttpMethod } from "./services/api.constant";

import ProductCard from "./pages/Products/components/ProductCard";

function App() {
  const [notificationPermission, setNotificationPermission] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, auth } = useSelector((state: RootState) => state?.auth);

  useEffect(() => {
    if (location?.pathname === "/notifications" && !auth?.access_token) {
      navigate("/login");
    } else if (location?.pathname === "/notifications") handleSeenAll();
    else if (location?.pathname === "/account") {
      !auth?.access_token && navigate("/login");
    }
  }, [location?.pathname]);

  const handleSeenAll = async () => {
    const respon = await callHttpClientMethod(
      BASE_URL + seenAll(),
      HttpMethod.Post,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    if (respon?.status_code === 200) {
      handleCountUnRead();
    }
  };

  const handleCountUnRead = async () => {
    console.log("Thực hiện call api count read noti");

    try {
      const respon = await callHttpClientMethod(
        BASE_URL + countReadUnNoti(),
        HttpMethod.Get,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth?.access_token}`,
          },
        }
      );
      if (respon?.status_code === 200) {
        dispatch({ type: TypesAction?.COUNT_NOTI, payload: respon?.data });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // Kiểm tra xem trình duyệt có hỗ trợ thông báo không
    if ("Notification" in window) {
      // Kiểm tra xem người dùng đã cấp quyền hiển thị thông báo cho ứng dụng chưa
      if (Notification.permission === "granted") {
        setNotificationPermission("granted");
        console.log("đã được cấp quyền");
      } else {
        // Nếu chưa cấp quyền, hiển thị cửa sổ yêu cầu quyền
        console.log("chưa được cấp quyền, thực hiện yêu cầu cấp quyền");

        requestPermission();
      }
    }
  }, []);
  useEffect(() => {
    isSupported().then(async (checkSupport) => {
      console.log("Support ", checkSupport);
      if (checkSupport) {
        const messaging = getMessaging(firebaseAppTBT);

        await getToken(messaging, {
          vapidKey: `BNT86BaULLv074jvLbHlligimv7MXjzoe-FbCzalxxds3fXQR8DxZZhx_sqMbqQlZXFooAUc4duwVBiXFf0rH68`,
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log(
                "=======Client Token FCM =============:",
                currentToken
              );
              dispatch({
                type: TypesAction.TOKEN_PUSH,
                payload: currentToken,
              });
            } else {
              console.log("Failed to generate the app registration token.");
            }
          })
          .catch((err) => {
            console.log(
              "An error occurred when requesting to receive the token.",
              err
            );
          });
      } else console.log("Support ", checkSupport);
    });
  }, []);

  useEffect(() => {
    isSupported().then(async (checkSupport) => {
      if (checkSupport) {
        const messaging = getMessaging(firebaseAppTBT);
        // requestPermission();

        const unsubscribe = onMessage(messaging, async (remoteMessage) => {
          console.log("Received push notification:", remoteMessage);
          toast.success(
            `Notification : ${
              remoteMessage?.data?.body || remoteMessage?.notification?.body
            }`
          );
        });

        return () => {
          unsubscribe();
        };
      }
    });
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/products-plants" element={<Products />} />
        <Route path="/products-pots" element={<Products />} />
        <Route path="/search" element={<ProductCard />} />
        <Route path="/product-details/:id" element={<ProductDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/account" element={<Account />} />
        <Route path="/validateOtp" element={<ValidateOtp />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/order-detail" element={<OrderHistoryDetail />} />
        <Route path="/notifications" element={<ListNotification />} />
      </Routes>
    </>
  );
}

export default App;
