import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  Modal,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
} from "@mui/material";
import { useEffect, useState } from "react";
import "./style.scss";
import { theme } from "../../utils/theme";
import { Link, useNavigate } from "react-router-dom";
import Container from "../../components/Header/common/Container";
import { callHttpClientMethod } from "../../services/callHttpClient";
import { BASE_URL, HttpMethod } from "../../services/api.constant";
import { login, resendOtpRegister, userInfo } from "../../services/apiList";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { TypesAction } from "../../redux/typesAction";

function Login() {
  const { tokenPush } = useSelector(
    (state: RootState) => state.listNotification
  );
  const { verify } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(`${verify}`);
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(true);

  const responsLogin = { width: { xs: "100%", sm: "70%" } };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  useEffect(() => {
    dispatch({
      type: TypesAction.AUTH,
      payload: {},
    });
    dispatch({
      type: TypesAction.USERINFO,
      payload: {},
    });
    dispatch({
      type: TypesAction.RESET_CART,
    });
  }, []);

  const handleResendOtp = async () => {
    const data = {
      email: verify,
    };
    await callHttpClientMethod(
      BASE_URL + resendOtpRegister(),
      HttpMethod.Post,
      data,
      {}
    );
  };
  const handleSubmitLogin = async () => {
    setLoading(true);
    const body = {
      token_push: tokenPush,
      email: email,
      password: password,
    };

    const responsive = await callHttpClientMethod(
      BASE_URL + login(),
      HttpMethod.Post,
      body,
      {}
    );
    if (responsive.status_code === 200 && responsive.access_token) {
      dispatch({
        type: TypesAction.AUTH,
        payload: { data: responsive },
      });
      const dataUser = await callHttpClientMethod(
        BASE_URL + userInfo(),
        HttpMethod.Get,
        {},
        {
          headers: {
            Authorization: `Bearer ${responsive.access_token}`,
          },
        }
      );

      dispatch({
        type: TypesAction.USERINFO,
        payload: { dataUser: dataUser.data, loadingUser: false },
      });

      navigate("/home");
      setLoading(false);
    } else if (
      responsive?.status_code === 500 &&
      responsive?.isVeryfyEmail === 0
    ) {
      navigate("/validateOtp", { state: 1 });
      handleResendOtp();
    } else {
      dispatch({ type: TypesAction.AUTH, payload: { loading: false } });
      setLoading(false);
      handleOpen();
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    width: { xs: "75%", sm: "50%", md: "35%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ padding: 0 }}>
      <Box className="bg_box_login">
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography
            sx={{
              fontSize: 25,
              textAlign: "center",
              color: `${theme.palette.text.body}`,
              fontWeight: 600,
            }}>
            LOGIN
          </Typography>
          <TextField
            sx={[responsLogin, { border: "none" }]}
            onChange={(e) => setEmail(e.target.value)}
            required
            error={!regex.test(email) && email.length > 0}
            disabled={loading}
            type="email"
            value={email}
            color={!regex.test(email) && email.length > 0 ? "error" : "success"}
            placeholder={"Enter email"}></TextField>
          {!regex.test(email) && email.length > 0 && (
            <Typography
              sx={[
                responsLogin,
                {
                  color: "#f02849",
                  marginTop: -2,
                  textAlign: "left",
                  marginLeft: "25px",
                },
              ]}>
              Email invalidate.
            </Typography>
          )}
          <OutlinedInput
            sx={responsLogin}
            required
            type={showPassword ? "password" : "text"}
            error={password.length < 8 && password.length > 0}
            value={password}
            disabled={loading}
            onChange={(e) => setPassword(e.target.value)}
            color={
              password.length < 8 && password.length > 0 ? "error" : "success"
            }
            placeholder={"Password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{ fontSize: 5 }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }></OutlinedInput>

          {password.length < 8 && password.length > 0 && (
            <Typography
              sx={[
                responsLogin,
                {
                  color: "#f02849",
                  marginTop: -2,
                  textAlign: "left",
                  marginLeft: "25px",
                },
              ]}>
              The password must be at least 8 characters
            </Typography>
          )}
          {!loading ? (
            <Button
              sx={{
                width: 200,
                paddingTop: 1,
                paddingBottom: 1,
                borderRadius: 5,
                marginBottom: 3,
                backgroundColor: "#e4e5e9",
                color: `${theme.palette.text.body}`,
                ":hover": { backgroundColor: "#e4e5e9" },
                fontWeight: 700,
              }}
              disabled={
                email === "" ||
                password === "" ||
                password.length < 8 ||
                !regex.test(email)
              }
              type="submit"
              onClick={handleSubmitLogin}
              variant="contained">
              Login
            </Button>
          ) : (
            <LoadingButton
              sx={{
                width: 200,
                paddingTop: 1,
                paddingBottom: 1,
                borderRadius: 5,
                marginBottom: 3,
                display: "flex",
                justifyContent: "center",
              }}
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              type="submit">
              Login
            </LoadingButton>
          )}
          <Box
            sx={[
              responsLogin,
              {
                marginBottom: "20px",
                border: 1,
                borderColor: "#dadde1",
                borderStyle: "solid",
              },
            ]}></Box>

          <Box
            sx={[
              responsLogin,
              {
                display: "flex",
                justifyContent: "space-between",
              },
            ]}>
            <Typography sx={{ textAlign: "center" }}>
              <Link
                style={{ color: `${theme.palette.text.body}` }}
                to={"/forgotPassword"}>
                Forgot password?
              </Link>
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              <Link
                style={{ color: `${theme.palette.text.body}` }}
                to={"/register"}>
                Create new account
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            sx={{ fontWeight: "bold", marginBottom: 1 }}
            className="title-error">
            Notification
          </Typography>
          <Typography>Your password or email is incorrect</Typography>
          <div className="line"></div>
          <Button
            color="primary"
            sx={{ width: "100%", fontWeight: 600, paddingBottom: -50 }}
            onClick={handleClose}>
            Ok
          </Button>
        </Box>
      </Modal>
    </Container>
  );
}

export default Login;
