import React, { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import { Divider, MenuItem, Toolbar, colors } from "@mui/material";
import { ButtonLogin, ButtonNoti } from "./header.style";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useLocation, useNavigate } from "react-router";

import Noti from "./Noti";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";

import { theme } from "../../utils/theme";
import { TypesAction } from "../../redux/typesAction";
import { PATH_IMAGE_URL } from "../../services/api.constant";
import "./style.scss";

import ListNotification from "../../pages/ListNotification";

const settings = ["Profile", "Account", "Dashboard", "Logout"];
const Profile: React.FC<{}> = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { auth, user } = useSelector((state: RootState) => state.auth);
  const { carts } = useSelector((state: RootState) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClickCart = () => {
    navigate("/cart");
    dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: false });
  };
  const handleNavRegister = () => {
    dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: false });
  };
  const handleHiddenBtn = () => {
    dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: false });
  };
  const handleAcount = () => {
    navigate("/account");
    dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: false });
  };

  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    // Xác định trang hiện tại và đặt lớp active tương ứng
    const path = location.pathname;
    setActiveLink(path);
  }, [location]);

  return (
    <Box
      sx={{
        display: {
          xs: "none",
          md: "flex",
        },
      }}>
      <Toolbar disableGutters sx={{ gap: "10px" }}>
        <>
          {!auth?.access_token ? (
            <>
              <ButtonLogin
                style={{ padding: "10px 11px" }}
                onClick={handleHiddenBtn}
                to="/login">
                Login
              </ButtonLogin>
              <ButtonLogin
                style={{ padding: "10px 11px" }}
                onClick={handleNavRegister}
                to="/register">
                Register
              </ButtonLogin>
            </>
          ) : (
            <>
              <Box
                style={{
                  fontWeight: 600,
                  color: activeLink == "/cart" ? "#007F18" : "#000",
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={handleClickCart}>
                {carts.length !== 0 && (
                  <Box
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: "red",
                      position: "absolute",
                      right: 0,
                      top: "-5px",
                      borderRadius: 10,
                      color: "white",
                      fontSize: 12,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    {carts.length}
                  </Box>
                )}
                <ShoppingCartOutlinedIcon
                  style={{
                    width: "38px",
                    height: "38px",
                  }}></ShoppingCartOutlinedIcon>
              </Box>
              <Noti />
              <Box onClick={handleAcount}>
                <Avatar alt="Remy Sharp" src={PATH_IMAGE_URL + user?.avatar} />
              </Box>
            </>
          )}
        </>
      </Toolbar>

      <Box sx={{ display: "none" }}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
        </IconButton>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}>
          {settings?.map((setting) => (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Menu
        sx={{
          marginTop: "30px",
          overflow: "visible",
          mt: 4,
        }}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}>
        <Box sx={{ maxWidth: "500px" }}>
          <ListNotification />
        </Box>
      </Menu>
    </Box>
  );
};
export default memo(Profile);
