import * as React from "react";

import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import {
  BASE_URL,
  HttpMethod,
  PATH_IMAGE_URL,
} from "../../../../services/api.constant";
import "./style.scss";
import { ProductSizes } from "../../../../gobalType";
import "react-alice-carousel/lib/scss/alice-carousel.scss";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import Modal from "@mui/material/Modal";

import { Box, CircularProgress } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import { convertToNumber } from "../../../../utils/storage";
import { TypesAction } from "../../../../redux/typesAction";
import { useMediaQuery } from "react-responsive";
import chat from "../../../../assets/Images/chat.png";
import { useLocation, useNavigate } from "react-router";
import { Carousel } from "react-responsive-carousel";

import DefaultText from "../../../../components/Text/DefaultText";
import { callHttpClientMethod } from "../../../../services/callHttpClient";
import { sizeDetail } from "../../../../services/apiList";

const ItemDetail = () => {
  const { auth } = useSelector((state: RootState) => state.auth);
  const [infoDetail, setInfoDetail] = React.useState<ProductSizes>();
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();

  const getSizeDetail = async () => {
    setLoading(true);
    const data = {
      product_id: location.state.product_id,
      size_id: location.state.size_id,
    };
    const response = await callHttpClientMethod(
      BASE_URL + sizeDetail(),
      HttpMethod.Get,
      data,
      {}
    );
    setLoading(false);
    setInfoDetail(response.data);
  };
  React.useEffect(() => {
    getSizeDetail();
  }, []);

  const [rightBtnDisabled, setRightBtnDisabled] = React.useState<boolean>(true);
  const [indexImg, setIndexImg] = React.useState(0);

  const defaultValue: any = ["Small"];
  let priceItem: any;
  if (infoDetail?.user_group_prices[0]) {
    let priceArray: any = infoDetail?.user_group_prices;
    priceItem = priceArray[0]?.price;
  }
  const [open, setOpen] = React.useState<boolean>(false);
  const [count, setCount] = React.useState("1");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const carousel = React.useRef<any>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isIphone = useMediaQuery({
    query: "(min-width: 390px) and (max-width : 429px) ",
  });

  React.useEffect(() => {
    if (infoDetail?.images.length > 1) {
      setRightBtnDisabled(false);
    }
  }, [infoDetail?.images.length]);
  const months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const styleClose = {
    position: "absolute",
    top: 12,
    right: -15,
    zIndex: 999,
    cursor: "pointer",
    transform: "translate(-50%, -50%)",
  };

  const styleArrImg = { sm: "80px", xs: "50px" };

  const getMonthById = (id: number) => {
    return months.find((month) => month.id == id);
  };

  function formatMoneyData(
    amount: number,
    currencySymbol = "$",
    thousandsSeparator = ","
  ) {
    const roundedAmount = Math.round(amount * 100) / 100;

    const amountString = roundedAmount.toFixed(2);

    const [integerPart] = amountString.split(".");

    const integerWithThousands = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      thousandsSeparator
    );

    return currencySymbol + integerWithThousands;
  }
  function formatQuantityData(amount: number, thousandsSeparator = ",") {
    const roundedAmount = Math.round(amount * 100) / 100;

    const amountString = roundedAmount.toFixed(2);

    const [integerPart] = amountString.split(".");

    const integerWithThousands = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      thousandsSeparator
    );

    return integerWithThousands;
  }

  const dataImg = infoDetail?.images;

  const convertPath = (path: string) => {
    return PATH_IMAGE_URL + path;
  };

  if (loading) {
    return (
      <Box
        sx={{
          margin: { xs: 15, sm: 32 },
          display: "flex",
          justifyContent: "center",
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }
  const renderCarousel = (modal?: any) => {
    return (
      <div className="active-image-detail">
        <Box
          className="image-product"
          sx={{
            alignItems: "center",
            width: { md: "100%", sm: "75%", xs: "90%" },
          }}>
          <Carousel
            showThumbs={false}
            selectedItem={indexImg}
            onChange={(e) => setIndexImg(e)}
            onClickItem={() => {
              setOpen(true);
            }}
            renderArrowPrev={({ isDisabled }: any) => (
              <KeyboardArrowLeftIcon
                className={`btn__left ${indexImg === 0 ? "disabled" : ""}`}
                onClick={() => setIndexImg(indexImg - 1)}
                sx={{
                  fontSize: isIphone ? 30 : 40,
                  display: {
                    sm: "block",
                    xs: "none",
                  },
                }}
              />
            )}
            renderArrowNext={() => (
              <ChevronRightIcon
                className={`btn__right ${
                  indexImg === dataImg?.length - 1 ? "disabled" : ""
                }`}
                onClick={() => setIndexImg(indexImg + 1)}
                sx={{
                  fontSize: isIphone ? 30 : 40,
                  display: {
                    sm: "block",
                    xs: "none",
                  },
                }}
              />
            )}>
            {dataImg?.map((item: any, index: any) => {
              const imagePath = PATH_IMAGE_URL + item.image_path;
              return (
                <>
                  <Box
                    key={index}
                    component={"img"}
                    src={imagePath}
                    sx={{
                      height: {
                        xs: "400px",
                        sm: "550px",
                        md: "450px",
                      },
                      backgroundColor: "#edeae9",
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                  />
                </>
              );
            })}
          </Carousel>
          <Box sx={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}>
            {dataImg?.map((item: any, index: any) => {
              return (
                <Box
                  className={`${indexImg === index ? "img_choiced" : ""}`}
                  key={index}
                  component={"img"}
                  src={convertPath(item.image_path)}
                  sx={{
                    width: styleArrImg,
                    height: styleArrImg,
                    cursor: "pointer",
                    borderRadius: "5px",
                    marginLeft: "5px",
                    objectFit: "cover",
                  }}
                  onClick={() => setIndexImg(index)}
                />
              );
            })}
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Box
        className="main-detail"
        sx={{
          display: {
            sm: "block",
            md: "flex",
            marginBottom: 40,
          },
        }}>
        {renderCarousel()}
        <Box className="info-product-detail" sx={{ padding: { xs: "10px" } }}>
          <div className="info-price" style={{ display: "flex" }}>
            <div className="name-detail ">{infoDetail?.product_name}</div>
            <div>
              <span className="title-price res-title">Price - </span>
              {infoDetail?.user_group_prices?.length !== undefined &&
              infoDetail?.user_group_prices?.length > 0 &&
              `${infoDetail?.user_group_prices[0].price}` !== "0" ? (
                <span className="price-detail res-title">
                  {formatMoneyData(priceItem)}
                </span>
              ) : (
                <span className="price-detail res-title">CALL</span>
              )}
            </div>
          </div>

          {infoDetail?.category_id === 1 ? (
            <div className="res-title choose-size ">
              <div className="res-title title-choose-size ">Size</div>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box className="btn-size">
                  <button>{infoDetail?.name}</button>
                </Box>
              </Box>
            </div>
          ) : (
            <div style={{ marginTop: 10 }}></div>
          )}

          <div className="dimension-detail">
            <span className=" title">Dimension:</span>
            <span className="res-title content-detail">
              {infoDetail?.dimension}
            </span>
          </div>

          <div className="available-detail">
            <span className=" title">Available:</span>

            <div className="quantity_available">
              {infoDetail?.quantity_available?.map(
                (value: ProductSizes | any, index: number | any) => {
                  const month = getMonthById(value.month);
                  const quantity = formatQuantityData(value.quantity);
                  return (
                    <li key={index} className="content-detail">
                      <span className="res-title month-detail">
                        {month?.name}:{" "}
                      </span>
                      <span className=" quanttity">{quantity}</span>
                      <span>
                        {infoDetail?.category_id === 1 ? "pcs" : "pallet"}
                      </span>
                    </li>
                  );
                }
              )}
            </div>
          </div>
          <div className="quantity-detail">
            <div className="title-quantity">
              <span className=" title">Quantity : </span>

              <span className="title descript">
                {infoDetail?.category_id === 1 ? "" : "10,000 pcs per pallet"}
              </span>
            </div>
            <div className="psc">
              <NumericFormat
                style={{
                  textAlign: "center",
                  fontSize: isIphone ? 15 : 16,
                  outline: "none",
                  borderColor: "#878787",
                  paddingLeft: -5,
                  paddingRight: -5,
                }}
                value={count}
                allowNegative={false}
                onChange={(e) => setCount(e.target.value)}
                thousandSeparator=","
              />

              <span>{infoDetail?.category_id === 1 ? "Pcs" : "Pallet"}</span>
            </div>
          </div>
          <div className=" total-contact">
            {infoDetail?.category_id === 1 ? (
              <div className=" contact-detail">
                <span>714-673-5386</span>
                <span>714-425-9975</span>
                <span>Sales@tbttropicalfarm.com</span>
              </div>
            ) : (
              <div className="  contact-detail">
                <span>407-714-4961</span>
                <span>714-425-9975</span>
                <span>Sales@tbtpotmanufacturing.com</span>
              </div>
            )}
            {isIphone ? (
              <div>
                <img src={chat} alt="image" />
              </div>
            ) : (
              <div
                className="btn-add-to-card"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  let product = {
                    ...infoDetail,
                    sizeName: infoDetail?.name,
                    size_id: infoDetail?.id,
                    quantity: convertToNumber(count),
                    price: Number(priceItem) || 0,
                    extractID: infoDetail?.product_id + "" + infoDetail?.id,
                    isCategory: infoDetail?.category_id === 1 ? "pcs" : "plt",
                    thumbnail: infoDetail?.images,
                  };
                  if (auth?.access_token) {
                    dispatch({
                      type: TypesAction.ADD_TO_CART,
                      payload: product,
                    });
                    toast.success("Added product to cart successfully");
                  } else navigate("/login");
                }}>
                <div>
                  <ShoppingCart />
                </div>
                <DefaultText>ADD TO CART</DefaultText>
              </div>
            )}
          </div>
          {isIphone && (
            <div className="btn-service">
              <div
                className="btn-add-to-card"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  fontSize: 14,
                }}
                onClick={() => {
                  if (auth?.access_token) {
                    navigate("/cart");
                  } else navigate("/login");
                }}>
                VIEW CART
              </div>
              <div
                className="btn-add-to-card"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 14,
                }}
                onClick={() => {
                  let product = {
                    ...infoDetail,
                    sizeName: infoDetail?.name,
                    size_id: infoDetail?.id,
                    quantity: convertToNumber(count),
                    price: Number(infoDetail?.user_group_prices[0]) || 0,
                    extractID: infoDetail?.product_id + "" + infoDetail?.id,
                    isCategory: infoDetail?.category_id === 1 ? "pcs" : "plt",
                    thumbnail: infoDetail?.images,
                  };
                  if (auth?.access_token) {
                    dispatch({
                      type: TypesAction.ADD_TO_CART,
                      payload: product,
                    });
                    toast.success("Added product to cart successfully");
                  } else navigate("/login");
                }}>
                ADD TO CART
              </div>
            </div>
          )}
        </Box>
      </Box>

      <Modal
        className="ZoomProduct"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Container
          maxWidth="md"
          sx={{
            position: "absolute",
            marginTop: 10,
            top: "50%",
            left: "50%",
            width: "94%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: 2,

            borderColor: "#ccc",
            boxShadow: 24,

            padding: "20px 20px",
          }}>
          <CloseIcon onClick={handleClose} sx={styleClose} />
          {renderCarousel(true)}
        </Container>
      </Modal>
    </React.Fragment>
  );
};
export default ItemDetail;
