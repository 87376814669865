import { useLocation, useNavigate } from "react-router";
import { BASE_URL, HttpMethod } from "../../../../services/api.constant";
import { detailHistoryOrder } from "../../../../services/apiList";
import { callHttpClientMethod } from "../../../../services/callHttpClient";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import {
  Box,
  Button,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import { BoxItem, Table, TableRow, Td, Th } from "./OrderDetail.style";
import Container from "../../../../components/Header/common/Container";
import { DataHistory } from "../../../../gobalType";
import moment from "moment";
import { formatMoneyData } from "../../../../utils/storage";
import { theme } from "../../../../utils/theme";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

import { TypesAction } from "../../../../redux/typesAction";
import "../../style.scss";
import TitleText from "../../../../components/Text/TitleText";
import DefaultText from "../../../../components/Text/DefaultText";

function OrderHistoryDetail() {
  const [status, setStatus] = useState(0);
  const { auth } = useSelector((state: RootState) => state.auth);
  const [orderDetail, setOrderDetail] = useState<DataHistory>();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  console.log(location, "test location in order history");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orderHistoryDetail = async () => {
    setLoading(true);
    const response = await callHttpClientMethod(
      BASE_URL + detailHistoryOrder(location?.state),
      HttpMethod.Get,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth.access_token}`,
        },
      }
    );
    setOrderDetail(response.data);
    setLoading(false);
  };
  const activeStatusOrder = () => {
    switch (orderDetail?.status) {
      case "ORDER_PLACED":
        setStatus(1);
        break;
      case "ORDER_APPROVED":
        setStatus(2);
        break;
      case "ORDER_SHIPPING":
        setStatus(3);
        break;
      case "ORDER_ARRIVED":
        setStatus(4);
        break;
      case "ORDER_PAID":
        setStatus(5);
        break;
    }
  };

  useEffect(() => {
    activeStatusOrder();
  }, [orderDetail?.status]);

  useEffect(() => {
    orderHistoryDetail();
  }, []);
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: { xs: 15, sm: 32 },
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }
  const styleStatusOrder = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const styleIcon = {
    width: { xs: "40px", sm: "50px" },
    height: { xs: "40px", sm: "50px" },
  };

  let totalPrice = 0;
  let amount = 0;
  return (
    <Container
      className="bg_order"
      maxWidth="md"
      component="main"
      sx={{
        backgroundColor: "#fff",
        paddingTop: "20px",
        padding: "10px",
      }}>
      <Typography
        sx={{
          fontWeight: 600,
          marginBottom: 2,
          fontSize: { xs: "16px", sm: "24px" },
          textAlign: "center",
          marginTop: { xs: "-40px", sm: "0px" },
        }}>
        ORDER DETAIL
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <DefaultText>
          Order No:{" "}
          <span style={{ fontWeight: 600 }}>{orderDetail?.order_code}</span>
        </DefaultText>
        <DefaultText>
          {moment(orderDetail?.created_at).format("DD/MM/YYYY")}
        </DefaultText>
      </Box>
      <Table className="table" aria-label="caption table">
        <TableHead>
          <TableRow className="table-head table-row-status">
            <TableCell
              id="status"
              align="left"
              sx={{
                border: "none",
                padding: 0,
                color:
                  1 <= status ? `${theme.palette.common.green}` : "#898A8D",
              }}>
              Order
            </TableCell>
            <TableCell
              id="status"
              align="left"
              sx={{
                border: "none",
                padding: 0,
                color:
                  2 <= status ? `${theme.palette.common.green}` : "#898A8D",
              }}>
              Approved
            </TableCell>
            <TableCell
              id="status"
              align="left"
              sx={{
                border: "none",
                padding: 0,
                color:
                  3 <= status ? `${theme.palette.common.green}` : "#898A8D",
              }}>
              Shipping
            </TableCell>
            <TableCell
              id="status"
              align="left"
              sx={{
                border: "none",
                padding: 0,
                color:
                  4 <= status ? `${theme.palette.common.green}` : "#898A8D",
              }}>
              Delivered
            </TableCell>
            <TableCell
              id="status"
              align="left"
              sx={{
                border: "none",
                padding: 0,
                color:
                  5 <= status ? `${theme.palette.common.green}` : "#898A8D",
              }}>
              Payment
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow className="table-row-status">
            <TableCell
              style={{
                color:
                  1 <= status ? `${theme.palette.common.green}` : "#898A8D",
              }}
              id="item-cell"
              align="left">
              <BoxItem>
                <Box>
                  <LocalMallOutlinedIcon sx={styleIcon} />
                </Box>
                <Box
                  sx={{
                    borderBottom: "2px solid ",
                    flex: 1,
                    borderColor:
                      3 <= status ? `${theme.palette.common.green}` : "#898A8D",
                  }}></Box>
              </BoxItem>
            </TableCell>
            <TableCell
              style={{
                color:
                  2 <= status ? `${theme.palette.common.green}` : "#898A8D",
              }}
              id="item-cell"
              align="left">
              <BoxItem>
                <Box>
                  <AssignmentTurnedInOutlinedIcon sx={styleIcon} />
                </Box>
                <Box
                  sx={{
                    borderBottom: "2px solid ",
                    flex: 1,
                    borderColor:
                      3 <= status ? `${theme.palette.common.green}` : "#898A8D",
                  }}></Box>
              </BoxItem>
            </TableCell>
            <TableCell
              style={{
                color:
                  3 <= status ? `${theme.palette.common.green}` : "#898A8D",
              }}
              id="item-cell"
              align="left">
              <BoxItem>
                <Box>
                  <LocalShippingOutlinedIcon sx={styleIcon} />
                </Box>
                <Box
                  sx={{
                    borderBottom: "2px solid ",
                    flex: 1,
                    borderColor:
                      3 <= status ? `${theme.palette.common.green}` : "#898A8D",
                  }}></Box>
              </BoxItem>
            </TableCell>
            <TableCell
              style={{
                color:
                  4 <= status ? `${theme.palette.common.green}` : "#898A8D",
              }}
              id="item-cell"
              align="left">
              <BoxItem>
                <Box>
                  <StoreOutlinedIcon sx={styleIcon} />
                </Box>
                <Box
                  sx={{
                    borderBottom: "2px solid ",
                    flex: 1,
                    borderColor:
                      3 <= status ? `${theme.palette.common.green}` : "#898A8D",
                  }}></Box>
              </BoxItem>
            </TableCell>
            <TableCell
              style={{
                color:
                  5 <= status ? `${theme.palette.common.green}` : "#898A8D",
              }}
              id="item-cell"
              sx={{
                padding: 0,
                border: "none",
              }}
              align="left">
              <MonetizationOnOutlinedIcon sx={styleIcon} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table aria-label="caption table">
        <TableHead>
          <TableRow
            style={{ backgroundColor: "#e5e5e5", cursor: "default" }}
            className="table-head">
            <TableCell id="cell" align="center">
              PRODUCT
            </TableCell>
            <TableCell id="cell" align="center">
              SIZE
            </TableCell>
            <TableCell id="cell" align="center">
              QTY
            </TableCell>
            <TableCell id="cell" align="center">
              PRICE
            </TableCell>
            <TableCell id="cell" align="center">
              AMOUNT
            </TableCell>
            <TableCell id="cell" align="center"></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {orderDetail?.order_details?.map((item: any, index: number) => {
            amount = item.total * item.price;
            totalPrice += Number(amount);
            return (
              <TableRow key={index}>
                <TableCell
                  id="cell"
                  align="center"
                  sx={{ textAlign: "left !important" }}>
                  <DefaultText>{item?.product?.name}</DefaultText>
                </TableCell>
                <TableCell id="cell" align="center">
                  {item.size.name !== "default" && item.size.name}
                </TableCell>
                <TableCell id="cell" align="right">
                  <DefaultText style={{ marginRight: "10px" }}>
                    {item.total}
                  </DefaultText>
                </TableCell>
                <TableCell id="cell" align="right">
                  <DefaultText style={{ marginRight: "10px" }}>
                    {formatMoneyData(item.price)}
                  </DefaultText>
                </TableCell>
                <TableCell id="cell" align="right">
                  <DefaultText style={{ marginRight: "10px" }}>
                    {formatMoneyData(amount)}
                  </DefaultText>
                </TableCell>
                <TableCell id="cell" align="center">
                  <Button
                    sx={{
                      borderRadius: 5,
                      backgroundColor: "#e4e5e9",
                      color: `${theme.palette.text.body}`,
                      ":hover": { backgroundColor: "#e4e5e9" },
                      fontWeight: 700,
                      padding: { xs: "5px 5px", sm: "6px 16px" },
                    }}
                    onClick={() => {
                      let product = {
                        ...item.product,
                        sizeName: item.size.name,
                        size_id: item.size.id,
                        quantity: Number(orderDetail.total_qty),
                        price: Number(item.price) || 0,
                        extractID: item.size.id + "" + orderDetail.id,
                        isCategory: item.category_id == 1 ? "pcs" : "plt",
                      };

                      dispatch({
                        type: TypesAction.ADD_TO_CART,
                        payload: product,
                      });
                      navigate("/cart");
                    }}
                    variant="contained">
                    <DefaultText>BUY AGAIN</DefaultText>
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableHead>
          <TableRow style={{ cursor: "default !important " }}>
            <TableCell
              id="cell"
              colSpan={3}
              align="center"
              sx={{
                borderBottom: "0 !important",
                backgroundColor: "#fff !important",
              }}></TableCell>
            <TableCell
              id="cell"
              align="center"
              sx={{ borderBottom: "0 !important" }}>
              <TitleText>TOTAL</TitleText>
            </TableCell>
            <TableCell
              id="cell"
              align="right"
              sx={{ borderBottom: "0 !important" }}>
              <TitleText style={{ marginRight: "10px" }}>
                {formatMoneyData(totalPrice)}
              </TitleText>
            </TableCell>
            <TableCell
              id="cell"
              align="center"
              sx={{
                borderBottom: "0 !important",
                backgroundColor: "#fff !important",
              }}></TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </Container>
  );
}

export default OrderHistoryDetail;
