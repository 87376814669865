import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ScrollToArrow from "../../components/ScrollToArrow";
import { productLists } from "../../services/apiList";
import Plants from "./PlantsPage";
import { BASE_URL, HttpMethod } from "../../services/api.constant";
import { callHttpClientMethod } from "../../services/callHttpClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";

import { CircularProgress } from "@mui/material";
import { TypesAction } from "../../redux/typesAction";

const Products = () => {
  const dispatch = useDispatch();
  const { category_id } = useSelector((state: RootState) => state.products);
  const [loading, setLoading] = React.useState(false);
  const loadData = async () => {
    dispatch({
      type: TypesAction.GET_LIST_PRODUCTS,
      payload: { loadingPf: true },
    });

    const data = {
      category_id: 1,
      search: "",
      order_by: "desc",
    };
    setLoading(true);

    const response = await callHttpClientMethod(
      BASE_URL + productLists(),
      HttpMethod.Get,
      data,
      {}
    );

    dispatch({
      type: TypesAction.GET_LIST_PRODUCTS,
      payload: { data: response, loadingPf: false },
    });
    setLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, [category_id]);

  if (loading) {
    return (
      <Box
        sx={{
          margin: { xs: 15, sm: 32 },
          display: "flex",
          justifyContent: "center",
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",

          marginTop: -12,
          placeItems: "center",
        }}>
        {category_id === 1 && <Plants />}
      </Box>
      <ScrollToArrow />
    </>
  );
};

export default React.memo(Products);
